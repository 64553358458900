<template>
  <div id="app">
    <!-- <label>
      切换为横向
      <input type="checkbox" v-model="landscape" value="1">
    </label> -->
    <TreeChart :json="data" :class="{ landscape: landscape.length }" @click-node="clickNode" />
  </div>
</template>

<script>
import TreeChart from "@/components/TreeChart";

export default {
  name: 'app',
  components: {
    TreeChart
  },
  data() {
    return {
      landscape: [],
      data: {
        name: '唐泰祥',
        image_url: "https://static.refined-x.com/static/avatar.jpg",
        isdie: true,
        years: '1895 ~ 1977',
        class: ["rootNode"],
        mate: [
          {
            name: 'mate',
            isdie: true,
            years: '?',
          }
        ],
        children: [
          {
            name: '唐居城',
            image_url: "https://static.refined-x.com/static/avatar.jpg",
            isdie: true,
            years: '1942 ~ 2019',
            mate: [
              {
                name: '高彩旗',
                isdie: false,
                years: '1910 ~ ?',
              }
            ],
            children: [
              {
                name: '唐红宗',
                image_url: "https://static.refined-x.com/static/avatar.jpg",
                isdie: true,
                years: '1971 ~ 2019'
              },
              // {
              //   name: '唐红娟',
              //   image_url: "https://static.refined-x.com/static/avatar.jpg",
              //   isdie: true,
              //   years: '1910 ~ ?',
              //   mate: [
              //     {
              //       name: 'mate',
              //       isdie: true,
              //       years: '1910 ~ 1978',
              //     }
              //   ],
              // },
              {
                name: '唐红敏',
                image_url: "https://static.refined-x.com/static/avatar.jpg",
                isdie: false,
                years: '1966 ~ ?',
                mate: [
                  {
                    name: '寇红侠',
                    isdie: false,
                    years: '1971 ~ ?',
                  }
                ],
                children: [
                  {
                    name: '唐源',
                    image_url: "https://static.refined-x.com/static/avatar.jpg",
                    isdie: false,
                    years: '1996 ~ ?',
                    mate: [
                      {
                        name: '娄兰',
                        isdie: false,
                        years: '1996 ~ ?',
                      }
                    ],
                  }, {
                    name: '唐栋',
                    image_url: "https://static.refined-x.com/static/avatar.jpg",
                    isdie: false,
                    years: '1993 ~ ?',
                    mate: [
                      {
                        name: '高莹',
                        isdie: false,
                        years: '1989 ~ ?',
                      }
                    ],
                  }
                ]
              }
            ]
          },

        ]
      }
    }
  },
  methods: {
    clickNode: function (node) {
      // eslint-disable-next-line
      console.log(node)
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#app .avat {
  border-radius: 2em;
  border-width: 2px;
}

#app .name {
  font-weight: 700;
}

#app .rootNode .name {
  /* color: red; */
}

.foot {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #333;
  padding: 24px;
  overflow: hidden;
  color: #999;
  font-size: 14px;
  text-align: center;
}

.foot a {
  color: #fff;
  margin: 0 .5em
}
</style>
